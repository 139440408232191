import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/layout"
import indexStyles from "../styles/pages/index.module.scss"
import Cover from "../images/cover.jpg"
import Clip1 from "../videos/murrina_clip1.mp4"
import Clip2 from "../videos/murrina_clip2.mp4"
import Clip3 from "../videos/murrina_clip3.mp4"
import Clip4 from "../videos/murrina_clip4.mp4"
import Clip5 from "../videos/murrina_clip5.mp4"
import Clip6 from "../videos/murrina_clip6.mp4"
import Clip7 from "../videos/murrina_clip7.mp4"
import Clip8 from "../videos/murrina_clip8.mp4"
import Clip9 from "../videos/murrina_clip9.mp4"


const Home = ({ location }) => {

  const videos =[Clip1, Clip2, Clip3, Clip4, Clip5, Clip6, Clip7, Clip8, Clip9]

  const video = videos[Math.floor(Math.random() * videos.length)];

  return (

    <Layout
      pageMeta={{
        title: "Negozio Online",
        keywords: ["murano", "lavorazione vetro di Murano", "La Murrina", "Venezia", "lampade", "gioielli", "illuminazione", "oggettistica"],
        description:
          "Il negozio online La Murrina Borgomanero offre articoli di illuminazione, oggettistica, gioielli, orologi e bigiotteria in vetro di murano, argento e legno",
        link: "/",
      }}
      location={location}
    >

<div className={indexStyles.btns}>
    <Link to="/oggettistica" className={indexStyles.btn}>Oggettistica</Link>
    <Link to="/gioielli" className={indexStyles.btn}>Gioielli</Link>
    <Link to="/orologi" className={indexStyles.btn}>Orologi</Link>
    <Link to="/pronta-consegna" className={indexStyles.btn}>Pronta Consegna</Link>
    {/* <Link to="/illuminazione" className={indexStyles.btn}>Illuminazione</Link> */}
    {/* <Link to="/cataloghi" className={indexStyles.btn}>Cataloghi</Link> */}
    </div>

    <video className={indexStyles.video} poster={Cover} loop autoPlay muted>
        <source src={video} type="video/mp4" />
        <source src={video}  type="video/ogg" />
        <img src={Cover} title="La Murrina logo" />
    </video>

    </Layout>
  )
}

export default Home